// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
// helpers
import { slugify } from '@helpers/string-helper'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 180,
    maxWidth: 400,
  },
  label: {
    fontSize: '12px',
    color: '#585860',
    marginBottom: '0.2rem',
  },
  menu: {
    '& > div': {
      minWidth: 180,
      paddingRight: 0,
    },
  },
  menuItem: {
    fontSize: '0.875rem',
  },
}))

// NOTE: if changes are made that alter the height of the Dropdown component, then adjust the value
// of MOBILE_OFFSET in src/pages/customer-service/common-questions.jsx to match the new height

const Dropdown = ({ activeNav, scrollToSection, sectionHeadings }) => {
  const classes = useStyles()
  const value = sectionHeadings?.find(h => h.navId === activeNav)?.heading ?? ''
  const handleChange = event => {
    const slugified = slugify(event.target.value)
    scrollToSection(`${slugified}-nav`, `${slugified}-section`)
  }

  return (
    <Box className={classes.wrapper}>
      <Typography component="label" className={classes.label}>
        Question Categories
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select className={classes.menu} value={value} onChange={handleChange}>
          {sectionHeadings.map(({ heading, id }) => (
            <MenuItem className={classes.menuItem} key={`${id}-mobile-dropdown`} value={heading}>
              {heading}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

Dropdown.propTypes = {
  activeNav: PropTypes.string,
  scrollToSection: PropTypes.func,
  sectionHeadings: PropTypes.array,
}

export default Dropdown
