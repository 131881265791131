import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, makeStyles, Typography } from '@material-ui/core'
import AccordionList from './AccordionList'
import Dropdown from './Dropdown'

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  dropdownWrapper: {
    position: 'sticky',
    top: ({ offset }) => offset,
    backgroundColor: 'white',
    opacity: 1,
    zIndex: 10,
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontWeight: '400',
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
  },
}))

const Content = ({
  activeNav,
  activeQuestionId,
  data,
  isMobile,
  offset,
  scrollToSection,
  sectionHeadings,
  setActiveNav,
  setActiveQuestionId,
  setSectionRefs,
}) => {
  const classes = useStyles({ offset })

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Typography component="h1" className={classes.pageTitle}>
          Common Questions
        </Typography>
      </Box>
      {isMobile && (
        <Box className={classes.dropdownWrapper}>
          <Dropdown activeNav={activeNav} scrollToSection={scrollToSection} sectionHeadings={sectionHeadings} />
          <Divider />
        </Box>
      )}
      <Box>
        {data.map(item => (
          <AccordionList
            key={item.id.concat('-content')}
            activeQuestionId={activeQuestionId}
            data={item}
            setActiveNav={setActiveNav}
            setActiveQuestionId={setActiveQuestionId}
            setSectionRefs={setSectionRefs}
          />
        ))}
      </Box>
    </Box>
  )
}

Content.propTypes = {
  activeNav: PropTypes.string,
  activeQuestionId: PropTypes.string,
  data: PropTypes.array,
  isMobile: PropTypes.bool,
  offset: PropTypes.number,
  scrollToSection: PropTypes.func,
  sectionHeadings: PropTypes.array,
  setActiveNav: PropTypes.func,
  setActiveQuestionId: PropTypes.func,
  setSectionRefs: PropTypes.func,
}

export default Content
