import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const useStyles = makeStyles(theme => ({
  accordion: {
    marginTop: ({ withMarginTop }) => (withMarginTop ? theme.spacing(2) : 0),
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
  borderNone: {
    borderBottom: 'solid 1px transparent',
    transition: 'border-bottom .3s ease',
    '&:hover': {
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  heading: {
    fontWeight: '600',
    fontSize: theme.typography.subtitle1.fontSize,
  },
  summary: {
    position: 'relative',
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  desktopSummary: {
    '&:before': {
      content: '"•"',
      position: 'absolute',
      left: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '1.25rem',
    },
  },
  expandedSummary: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  expandedContent: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: '1.25rem 0',
    },
  },
}))

// eslint-disable-next-line no-shadow
const Accordion = ({ accordion, accordionID, children = null, expanded, handleChange, withMarginTop = true }) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const classes = useStyles({ withMarginTop })
  const aria = `${accordionID}-content`
  const id = `${accordionID}-header`
  const customProps = { elevation: 0, square: true }
  const cookieESCProvider = cookies.get('__Host-rtg_location')?.esc_provider?.toLowerCase()

  const onKeyDown = event => {
    // Handle user pressing Esc key (code 27)
    const code = event.keyCode ?? event.which
    if (code === 27) {
      handleChange({}, false)
    }
  }

  if (accordion === null) return null

  if (accordion?.EscProvider && accordion?.EscProvider?.toLowerCase() !== cookieESCProvider) {
    return null
  }

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleChange}
      className={classNames(classes.accordion, { [classes.borderNone]: !expanded })}
      {...customProps}
    >
      <AccordionSummary
        className={classNames(classes.summary, {
          [classes.expandedSummary]: expanded,
          [classes.desktopSummary]: isDesktop,
        })}
        IconButtonProps={{ disableRipple: true }}
        onKeyDown={expanded ? onKeyDown : null}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={aria}
        id={id}
      >
        <Typography className={classes.heading}>{accordion?.Heading}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classNames(classes.content, { [classes.expandedContent]: expanded })}>
        {accordion?.Markdown && accordion?.Markdown?.data?.childMarkdownRemark && (
          <div
            id={accordionID}
            role="region"
            dangerouslySetInnerHTML={{
              __html: accordion.Markdown.data?.childMarkdownRemark.html,
            }}
          />
        )}
        {children && <div className="contentful-accordion-content">{children}</div>}
      </AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  accordion: PropTypes.object.isRequired,
  accordionID: PropTypes.string,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  handleChange: PropTypes.func,
  withMarginTop: PropTypes.bool,
}

export default Accordion
