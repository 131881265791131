import React, { useEffect, useMemo, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, Box, useMediaQuery } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
// Helpers
import { getRegionZone } from '@helpers/geo-location'
import { selectRegionBasedContent } from '@helpers/strapi'
import { slugify } from '@helpers/string-helper'
// components
import Breadcrumbs from '../../components/customer-service/common-questions/Breadcrumbs'
import Content from '../../components/customer-service/common-questions/Content'
import Drawer from '../../components/customer-service/common-questions/Drawer'
import Layout from '../../components/layout'

const DRAWER_WIDTH = 260
const OFFSET = 172
const MOBILE_OFFSET = 99 // matches height of the sticky categories Dropdown

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    '& h5': {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: '400',
      marginBottom: theme.spacing(1),
    },
  },
}))

const commonQuestionsQuery = graphql`
  query {
    strapiPage(strapi_id: { eq: 4842 }) {
      SEO {
        SEO {
          LD_Schema {
            internal {
              content
            }
          }
        }
      }
      DefaultContent {
        ... on PageAccordionList {
          AccordionList {
            Title
            id
            Accordions {
              Accordion {
                Heading
                Title
                id
                Markdown {
                  data {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                Hidden
                EscProvider
              }
            }
          }
        }
      }
      Content_SE {
        ... on PageAccordionList {
          AccordionList {
            Title
            id
            Accordions {
              Accordion {
                Heading
                Title
                id
                Markdown {
                  data {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                Hidden
                EscProvider
              }
            }
          }
        }
      }
      Content_FL {
        ... on PageAccordionList {
          AccordionList {
            Title
            id
            Accordions {
              Accordion {
                Heading
                Title
                id
                Markdown {
                  data {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                Hidden
                EscProvider
              }
            }
          }
        }
      }
      Content_TX {
        ... on PageAccordionList {
          AccordionList {
            Title
            id
            Accordions {
              Accordion {
                Heading
                Title
                id
                Markdown {
                  data {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                Hidden
                EscProvider
              }
            }
          }
        }
      }
      Content_OOM {
        ... on PageAccordionList {
          AccordionList {
            Title
            id
            Accordions {
              Accordion {
                Heading
                Title
                id
                Markdown {
                  data {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                Hidden
                EscProvider
              }
            }
          }
        }
      }
    }
  }
`

const CommonQuestions = ({ hideInfoBanner, ...props }) => {
  const { region } = getRegionZone() // Get current region to control individual variant availability
  const myData = useStaticQuery(commonQuestionsQuery)
  const cqData = useMemo(
    () =>
      selectRegionBasedContent(region, myData.strapiPage)
        ?.filter(item => item.AccordionList)
        .map(({ AccordionList }) => {
          const heading = AccordionList.Title.replace(/Common Question: |\s\(SE\)|\s\(FL \+ TX\)/g, '')
          return {
            accordions: AccordionList.Accordions,
            id: AccordionList.id,
            heading,
            navId: `${slugify(heading)}-nav`,
          }
        }) ?? [],
    [myData, region],
  )

  const sectionHeadings = cqData.map(({ heading, id, navId }) => ({ heading, id, navId }))

  const formattedLdSchema = useMemo(() => {
    const ldSchema = myData.strapiPage?.SEO?.SEO?.LD_Schema?.internal?.content
    if (!ldSchema) return null

    return ldSchema
  }, [myData])

  const [activeNav, setActiveNav] = useState(sectionHeadings[0]?.navId ?? '')
  const [activeQuestionId, setActiveQuestionId] = useState('')
  const [sectionRefs, setSectionRefs] = useState([])
  const [headerHeight, setHeaderHeight] = useState(OFFSET)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isNarrow = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const classes = useStyles()
  const timeoutRef = useRef(null)

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setHeaderHeight(document.querySelector('header')?.getBoundingClientRect()?.bottom ?? OFFSET)
    }, 250)

    return () => clearTimeout(timeoutRef.current)
  }, [isMobile, isNarrow, hideInfoBanner])

  const scrollToSection = (navId, sectionId) => {
    setActiveQuestionId('')
    const section = sectionRefs.find(({ current }) => current.id === sectionId)?.current
    if (typeof window !== 'undefined' && section) {
      const top =
        section.getBoundingClientRect().top + window.pageYOffset - headerHeight - (isMobile ? MOBILE_OFFSET : 0)
      window.scrollTo({ top, behavior: 'smooth' })
    }
    setActiveNav(navId)
  }

  return (
    <>
      <Helmet title="Common Questions - Rooms To Go">
        <script type="application/ld+json">{formattedLdSchema}</script>
      </Helmet>
      <Layout {...props}>
        <Breadcrumbs />
        <Box className={classes.wrapper}>
          {!isMobile && (
            <Drawer
              activeNav={activeNav}
              data={sectionHeadings}
              drawerWidth={DRAWER_WIDTH}
              offset={headerHeight}
              scrollToSection={scrollToSection}
            />
          )}
          <Content
            activeNav={activeNav}
            activeQuestionId={activeQuestionId}
            data={cqData}
            isMobile={isMobile}
            offset={headerHeight}
            scrollToSection={scrollToSection}
            sectionHeadings={sectionHeadings}
            setActiveNav={setActiveNav}
            setActiveQuestionId={setActiveQuestionId}
            setSectionRefs={setSectionRefs}
          />
        </Box>
      </Layout>
    </>
  )
}

CommonQuestions.propTypes = {
  props: PropTypes.any,
  hideInfoBanner: PropTypes.bool,
}

const mapStateToProps = ({ global }) => ({ hideInfoBanner: !!global?.hideInfoBanner })

export default connect(mapStateToProps)(CommonQuestions)
