// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box, Divider, List } from '@material-ui/core'
// components
import NavItem from './NavItem'

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRight: `1px solid #dedede`,
    minWidth: ({ drawerWidth }) => drawerWidth,
    position: 'relative',
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
  list: {
    paddingTop: 0,
    position: 'sticky',
    top: ({ offset }) => offset,
    bottom: 20,
  },
}))

const Drawer = ({ activeNav, data = [], drawerWidth, offset, scrollToSection }) => {
  const classes = useStyles({ drawerWidth, offset })
  return (
    <Box component="nav" className={classes.wrapper}>
      <Divider />
      <List className={classes.list}>
        {data.map(({ heading, id, navId }) => (
          <NavItem key={id} heading={heading} isActive={activeNav === navId} scrollToSection={scrollToSection} />
        ))}
      </List>
    </Box>
  )
}

Drawer.propTypes = {
  activeNav: PropTypes.string,
  data: PropTypes.array,
  drawerWidth: PropTypes.number,
  offset: PropTypes.number,
  scrollToSection: PropTypes.func,
}

export default Drawer
