import React, { useEffect, useRef } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { slugify } from '@helpers/string-helper'
import Accordion from './Accordion'

const useStyles = makeStyles(theme => ({
  listWrapper: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  textWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listHeading: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
  },
}))

const AccordionList = ({
  activeQuestionId,
  data: { accordions, heading, navId },
  setActiveNav,
  setActiveQuestionId,
  setSectionRefs,
}) => {
  const sectionRef = useRef()
  const classes = useStyles()
  const slugifiedHeading = slugify(heading)

  useEffect(() => {
    if (sectionRef.current) {
      setSectionRefs(refs => [...refs, sectionRef])
    }
  }, [heading, setSectionRefs])

  const handleAccordion = questionId => (event, isExpanded) => {
    if (isExpanded) {
      setActiveNav(navId)
      setActiveQuestionId(questionId)
    } else {
      setActiveQuestionId('')
    }
  }
  const sectionId = `${slugifiedHeading}-section`

  return (
    <section id={sectionId} ref={sectionRef}>
      <div className={classes.textWrapper}>
        <Typography component="h3" className={classes.listHeading}>
          {heading}
        </Typography>
      </div>
      <div className={classes.listWrapper}>
        {accordions?.map(({ Accordion: accordion }) => {
          const id = accordion?.id
          if (!id) return null
          return (
            <Accordion
              key={id}
              accordion={accordion}
              accordionID={id}
              expanded={id === activeQuestionId}
              handleChange={handleAccordion(id)}
              withMarginTop={false}
            />
          )
        })}
      </div>
    </section>
  )
}

AccordionList.propTypes = {
  activeQuestionId: PropTypes.string,
  data: PropTypes.object.isRequired,
  setActiveNav: PropTypes.func,
  setActiveQuestionId: PropTypes.func,
  setSectionRefs: PropTypes.func,
}

export default AccordionList
