import React from 'react'
import RTGLink from '@shared/link'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  breadcrumbsBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
    '& li': {
      marginLeft: '0.3rem',
    },
  },
}))

const Breadcrumbs = () => {
  const classes = useStyles()
  return (
    <Box className={`${classes.breadcrumbsBox} breadcrumb grid-x grid-margin-x`} aria-label="breadcrumb">
      <ul className="breadcrumbs-list cell small-12 grid-x">
        <li>
          <RTGLink
            data={{
              slug: '/',
              category: 'breadcrumbs',
              action: 'home click',
              label: 'Home',
            }}
          >
            Home
          </RTGLink>
        </li>
        <li>
          &gt;{' '}
          <RTGLink
            data={{
              slug: '/customer-service',
              category: 'breadcrumbs',
              action: 'customer service click',
              label: 'Customer Service',
            }}
          >
            Customer Service
          </RTGLink>
        </li>
        <li className="current">&gt; Common Questions</li>
      </ul>
    </Box>
  )
}

export default Breadcrumbs
