// dependencies
import React from 'react'
import { makeStyles, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// icons
import { NavigateNext } from '@material-ui/icons'
// helpers
import { slugify } from '@helpers/string-helper'

const useStyles = makeStyles(theme => ({
  listItem: {
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      '& span': {
        color: theme.palette.text.secondary,
      },
    },
  },
  activeItem: {
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  action: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
}))

const NavItem = ({ heading, isActive, scrollToSection }) => {
  const classes = useStyles()
  const slugified = slugify(heading)

  return (
    <ListItem
      onClick={() => scrollToSection(`${slugified}-nav`, `${slugified}-section`)}
      className={classNames(classes.listItem, { [classes.activeItem]: isActive })}
      divider
      button
    >
      <ListItemText primary={heading} />
      <ListItemSecondaryAction className={classes.action}>
        <NavigateNext className={classes.icon} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

NavItem.propTypes = {
  heading: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  scrollToSection: PropTypes.func,
}

export default NavItem
